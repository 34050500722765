export default function () {

	$('#box-vitalite').append($('#box-vitalite-content').html());
	$('#box-metabolique').append($('#box-metabolique-content').html());

	$('#box-a-la-semaine').append($('#box-a-la-semaine-content').html());
	$('#box-au-mois').append($('#box-au-mois-content').html());
	$('#box-aux-deux-semaines').append($('#box-aux-deux-semaines-content').html());


	if ($(window).width() >= 500) {
		boxWidth();

		$(window).on('resize', boxWidth);

		function boxWidth() {
			var parentWidth = $('table.variations').width();

			$('.more-info-content').css('width', parentWidth)
		}
	}
}