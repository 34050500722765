export default function() {

	$(window).on('scroll', function() {
		var top = $(document).scrollTop();

		if (top >= 500) {
			$('.c-header').addClass('is-sticky');
		}
		if (top <= 1) {
			$('.c-header').removeClass('is-sticky');
		}
	});
}