export default function () {

	$('.js-text-slider').slick({
		infinite:true,
		slidesToShow: 1,
		slidesToScroll:1,
		dots:true,
		appendDots:'.js-dot-slider',
		fade:true,
		arrows:false,
		margin:0,
		asNavFor:'.js-image-slider',

	});

	$('.js-image-slider').slick({
		infinite:true,
		slidesToShow: 1,
		slidesToScroll:1,
		fade:true,
		arrows:false,
		dots:false,
		margin:0,
		asNavFor:'.js-text-slider',

	});


	$('.js-equipe-nav div').on('click', function() {

		var navIndex = $(this).index();

		$('.js-equipe-slider').slick('slickGoTo', navIndex);
	})

	slickEquipe();

	$(window).resize(function() {
		slickEquipe();
	})

	function slickEquipe() {
		$('.js-equipe-slider').not('.slick-initialized').slick({
			infinite:true,
			slidesToScroll:1,
			slidesToShow:1,
			dots:false,
			arrows:true,
			prevArrow:'.js-equipe-prev',
			nextArrow:'.js-equipe-next',
			responsive:[{
				breakpoint: 900,
				settings: 'unslick'
			}] 
		}).on('afterChange', function() {

			var currentSlide = $('.js-equipe-slider').slick('slickCurrentSlide');

			$('.js-equipe-nav div').removeClass('active');
			$('.js-equipe-nav div:eq(' + currentSlide + ')').addClass('active');

		});
	}


	$('.js-product-gallery').slick({
		slidesToShow:1,
		slidesToScroll:1,
		infinite:true,
		arrows:false,
		dots:false,
		fade:true,
	});

	$('.js-product-gallery-thumbnails').slick({
		slidesToShow:4,
		slidesToScroll:1,
		infinite:false,
		arrows:false,
		dots:false,
		asNavFor:'.js-product-gallery',
		focusOnSelect:true,
		responsive: [
			{
				breakpoint: 479,
				settings: {
					slidesToShow:2,
				}
			}
		]
	});
}