export default function() {
	jQuery('.button-variation').click(function() {
		jQuery('.button-variation').removeClass('active');
		jQuery(this).addClass('active');

		if (jQuery(this).find('input[name="attribute_pa_frequence"]').val() == 'commande-unique') {
			jQuery('.variations_form').addClass('commande-unique');
			jQuery('.single_variation_wrap .single_add_to_cart_button').text('Ajouter au panier');
		} else {
			jQuery('.variations_form').removeClass('commande-unique');
			jQuery('.single_variation_wrap .single_add_to_cart_button').text("M'abonner");
		}
	});
}