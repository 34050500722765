import scroller from './modules/scroller';
import placeholder from './modules/placeholder';
import slider from './modules/slider';
import accordion from './modules/accordion';
import wcSingle from './modules/wcSingle';
import sticky from './modules/sticky';
import singleChanger from './modules/singleChanger';
import mobileNav from './modules/mobileNav';
import infoBox from './modules/infoBox';

scroller();
placeholder();
slider();
accordion();
wcSingle();
sticky();
singleChanger();
mobileNav();
infoBox();
