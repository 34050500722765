export default function () {

	$('.js-toggle-nav').click(function() {
		$('.js-mobile-nav').toggleClass('active');
	})

	$('.js-close-nav').click(function() {
		$('.js-mobile-nav').removeClass('active');
	})

	$(document).keyup(function(e) {
		if (e.keyCode == 27) {
			$('.js-mobile-nav').removeClass('active');
		}
	})

	$(document).scroll(function() {
		$('.js-mobile-nav').removeClass('active');
	})

}